<template>
  <l-map ref="map" :zoom="zoom" v-bind:center="[59.859466, 17.628551]">
    <l-tile-layer url= 'https://api.mapbox.com/styles/v1/mapbox/dark-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3VkZGVuaWZyeXNlbiIsImEiOiJjanBtamwxZ3UwMnl0M3hueDFoNzM1b3kxIn0.BVZBFHQEx4Nbi6jxoyJZmw#0.0/0.000000/0.000000/0'/>
    <l-marker v-for="(item, index) in markers"
      :lat-lng="item.location"
      :key="index + 1"
      :icon="item.icon"
      :title="item.name"/>
    <vue2-leaflet-heatmap :lat-lng="HeatData" :radius="20" :max-zoom="zoom"/>
  </l-map>
</template>

<style>
@import '~leaflet/dist/leaflet.css';
</style>

<script>
import L from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import Vue2LeafletHeatmap from '@/components/Vue2LeafletHeatmap'
import { Nations, SetupWatchPosition } from '../LocalService'
import axios from 'axios'

// Fixes default pin bug
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})
//

Nations.forEach(function (element) {
  element.icon = L.icon({
    iconUrl: element.iconURL,
    iconAnchor: [15, 15],
    iconSize: [30, 30]
  })
})

var globalHeatMap = []

export default {
  data () {
    return {
      markers: Nations,
      backUserLocation: [0, 0],
      zoom: 16
    }
  },
  mounted: function () {
    SetupWatchPosition(this.$refs.map.mapObject, (latlng) => {
      this.backUserLocation = [latlng.lat, latlng.lng]
    })
    axios.get('https://api.futf.se/api/user/read.php')
      .then(result => {
        globalHeatMap.length = 0
        for (let i = 0; i < result.data.length; i++) {
          let obj = result.data[i]
          // Temporary solution to the weird null post bug
          if (obj.latitude != '' && obj.longitude != '') {
            globalHeatMap.push([parseFloat(obj.latitude), parseFloat(obj.longitude), 0.5])
          }
        }
        // response.data.positions.forEach(element => {
        //   globalHeatMap.push([parseFloat(element.lat), parseFloat(element.long), 0.5])
        // })
      })
  },
  beforeDestroy: function () {
    this.$refs.map.mapObject.stopLocate()
    console.log('My watch has ended')
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    Vue2LeafletHeatmap
  },
  computed: {
    userLocation: function () {
      return this.backUserLocation
    },
    HeatData: function () {
      return globalHeatMap
    }
  }
}
</script>
