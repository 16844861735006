<template>
  <card-container>
    <v-toolbar color="#f1b434">
      <v-toolbar-title >Nationer</v-toolbar-title>
      <v-spacer/>
      <v-dialog width ="500"
        v-model="dialogVisible">
        <v-btn slot="activator"
          flat>
          Lägg till varning
        </v-btn>

        <new-warning v-on:cancel="dialogVisible=false"
          v-on:send="onNewWarning"/>

      </v-dialog>
    </v-toolbar>

    <v-list>
      <div v-for="item in nationsAndWarnings" :key="item.name">
        <v-list-tile v-if="!item.isopen" disabled> <!-- Use this if closed -->
          <v-list-tile-avatar>
              <img :src="item.iconURL">
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ item.name + ((typeof(item.permatext) === 'undefined') ? '' : ` (${item.permatext})`) }}</v-list-tile-title>
            </v-list-tile-content>
        </v-list-tile>

        <v-list-group v-else> <!-- Use this if open -->
          <v-list-tile slot="activator">

            <v-list-tile-avatar>
              <img :src="item.iconURL">
            </v-list-tile-avatar>

            <v-list-tile-content>
              <v-list-tile-title>{{ item.name + ((typeof(item.permatext) === 'undefined') ? '' : ` (${item.permatext})`) }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action v-if="item.warnings.length > 0">
              <v-icon color="#F1B434">warning</v-icon>
            </v-list-tile-action>
          </v-list-tile>
          <v-list-tile v-if="item.warnings.length === 0">
            <v-list-tile-content>
              <v-list-tile-title>Tomt!</v-list-tile-title>
              <v-list-tile-sub-title>Ingen statusinformation finns.</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>

          <v-list-tile
            v-for="(subItem, index) in item.warnings"
            :key="item.name + index"
          >
            <v-list-tile-content>
              <v-list-tile-title>{{subItem.msg}}</v-list-tile-title>
              <v-list-tile-sub-title>{{subItem.time}}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
      </div>
    </v-list>
  </card-container>
</template>

<script>
import { Nations, GetCurrentTime } from '../LocalService'
import { nationPermastatus } from '@/MockedBackend'
import axios from 'axios'
import CardContainer from '../components/CardContainer'
import NewWarning from '../components/NewWarningComponent'

export default {
  data: function () {
    return {
      nations: Nations,
      dialogVisible: false,
      warnings: []
    }
  },
  components: {
    CardContainer,
    NewWarning
  },
  methods: {
    onNewWarning: function (args) {
      var index = Nations.filter(n => n.name === args.Nation)[0].index
      var uname = localStorage.username
      if (uname !== undefined) {
        // axios.get('https://futf.se/API/updateWarning', { params: {
        //   uname: uname,
        //   msg: args.Message,
        //   nation: index,
        //   time: GetCurrentTime()
        // } }).then(this.updateWarnings())
        axios.post('https://api.futf.se/api/warning/create.php', { 
          username: uname,
          msg: args.Message,
          nation: index,
          time: GetCurrentTime()
        }).then(this.updateWarnings())
        console.log('pushed')
      }
      this.dialogVisible = false
    },
    updateWarnings: function () {
      axios.get('https://api.futf.se/api/warning/read.php')
        .then(response => {
          // TEMPORARY fix for the god forsaken bug
          let cleaned_warnings = response.data.filter(w => w.username !== '')

          // Convert nation index from string to int
          for (let i = 0; i < cleaned_warnings.length; i++) {
            cleaned_warnings[i].nation = parseInt(cleaned_warnings[i].nation)
          }

          this.warnings = cleaned_warnings
        })
      console.log('request sent')
    }
  },
  mounted: function () {
    this.updateWarnings()
  },
  computed: {
    nationsAndWarnings: function () {
      var _nations = Array.from(this.nations)
      var _warnings = this.warnings

      _nations.forEach(element => {
        element.warnings = _warnings.filter(w => w.nation === element.index).sort((a, b) => b.time.localeCompare(a.time))
        element.isopen = nationPermastatus[element.index].open
        element.permatext = nationPermastatus[element.index].message
      })
      return _nations
    }
  }
}
</script>
