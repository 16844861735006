<template>
  <v-container fluid>
    <v-layout fill-height>
      <map-component class="map"/>
    </v-layout>
  </v-container>
</template>

<style>
.container {
  height: 100%;
  display: table;
}
.map-container {
  display: table-row;
  height: 100%;
}
.map {
  display: table-cell;
  z-index: 1;
}
</style>

<script>
// Import relevant components, defined in 'src/componenets' (in this case MapComponenet)
import MapComponent from '../components/MapComponent'

// This part is what is to be used from within the template
export default {
  components: { // All componenets that will be used in template
    MapComponent
  },
  data () { // All data that will be needed in the template
    return {
      heatData: []
    }
  }
}
</script>
