<template>
  <v-card>
    <v-card-title>
      <span class="headline">Ny status</span>
    </v-card-title>
    <v-card-text>
    <v-form v-model="valid"
            ref="form">
      <v-container>
        <v-layout row wrap>
          <v-flex xs12 xs6 d-flex>
            <v-select
              :items="nations"
              label="Nation"
              :rules="[n => !!n || 'Obligatorisk']"
              outline
              v-model="nation"
              color="#f1b434"
            />
          </v-flex>
          <v-flex xs12  xs6>
            <v-select
              :items="messages"
              label="Vad har hänt?"
              :rules="[n => !!n || 'Obligatorisk']"
              outline
              v-model="message"
              color="#f1b434"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color=#f1b434
        :disabled="!valid"
        flat
        @click="onSend()">
        Skicka
      </v-btn>
      <v-btn color="error"
        flat
        @click="onCancel()">
        Avbryt
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Nations } from '../LocalService'

export default {
  data: function () {
    return {
      nations: Nations.map(n => n.name),
      messages: ['Det är fullt', 'De släpper inte in ovvar', 'Det finns massa plats', 'Lång kö'],
      valid: false,
      message: undefined,
      nation: undefined
    }
  },
  methods: {
    onCancel: function () {
      this.$emit('cancel')
      this.$refs.form.reset()
    },
    onSend: function () {
      this.$emit('send', { Nation: this.nation, Message: this.message })
      this.$refs.form.reset()
    }
  }
}
</script>
